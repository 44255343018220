<template>
  <div>
    <v-row>
      <v-col
        v-if="hideList"
        class="shrink"
      >
        <app-alert
          alert="time_span_edit"
          data-cy="alert"
        >
          <div
            class="text-subtitle-1"
            data-cy="alertTitle"
          >
            Zeitplan
          </div>
          <div data-cy="alertText">
            Hier können benutzerdefinierte Zeiträume wie Elternzeit oder Teilzeit oder auch
            Auszeiten wie zum Beispiel Forschungszeiten eingetragen werden.
          </div>
        </app-alert>
      </v-col>
    </v-row>
    <v-row v-if="hideList">
      <v-col cols="12">
        <p
          v-if="!periods.length"
          class="text-body-1"
        >
          Noch keine Ausfallzeiten angegeben.
        </p>
      </v-col>
    </v-row>
    <v-row>
      <v-fade-transition leave-absolute>
        <v-col
          v-if="loading"
          key="skeleton"
          cols="12"
          class="mb-0"
        >
          <v-card
            :elevation="1"
            data-cy="addedPlan"
            :class="'mb-0 sectionbordersecondary'"
          >
            <v-skeleton-loader type="list-item-two-line" />
          </v-card>
        </v-col>
      </v-fade-transition>
    </v-row>
    <v-row>
      <v-progress-linear
        indeterminate
        :active="loading"
      />
      <v-col
        v-if="!readonly && hasRole(['ADMINISTRATIVE_STAFF', 'ROTATING_STAFF', 'TRAINEE_BASIC'])"
      >
        <v-menu>
          <template #activator="{ props }">
            <v-card
              v-if="hasRole(['ADMINISTRATIVE_STAFF', 'ROTATING_STAFF', 'TRAINEE_BASIC'])"
              class="add-link default-height"
              color="transparent"
              data-cy="addTimePlan"
              v-bind="props"
              elevation="0"
            >
              <v-card-text class="text-center text-primary text-body-1">
                <v-icon>mdi-plus-circle-outline</v-icon>
                <span class="text-body-1 pt-2 pl-2">Eintrag hinzufügen</span>
              </v-card-text>
            </v-card>
          </template>
          <v-list>
            <v-list-item
              data-cy="addAbsence"
              @click="
                editPeriod = {
                  text: 'Fehlzeit',
                  type: 'ABSENCE',
                  percentage: 0,
                  start: new Date().toISOString().substring(0, 10),
                }
              "
            >
              <v-list-item-title>Fehlzeit</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="getPremiumFeatures().includes('PART_TIME')"
              data-cy="addPartTime"
              @click="editPeriod = { type: 'PART_TIME', text: 'Teilzeit', percentage: 50 }"
            >
              <v-list-item-title>Teilzeit</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="getPremiumFeatures().includes('MATERNITY_PROTECTION')"
              data-cy="addMaternityProtectionPeriod"
              @click="
                editPeriod = { type: 'MATERNITY_PROTECTION', text: 'Mutterschutz', percentage: 0 }
              "
            >
              <v-list-item-title>Mutterschutz</v-list-item-title>
            </v-list-item>
            <v-list-item
              data-cy="addTimeSchedule"
              @click="editPeriod = { percentage: 100 }"
            >
              <v-list-item-title>Sonstige Informationen</v-list-item-title>
            </v-list-item>
            <v-list-item
              v-if="hasRole(['ADMINISTRATIVE_STAFF'])"
              data-cy="addTermination"
              @click="
                editPeriod = {
                  text: 'Kündigung',
                  type: 'TERMINATION',
                  percentage: 0,
                  end: '2099-12-31',
                }
              "
            >
              <v-list-item-title>Kündigung</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <v-col
        v-for="period in periods"
        :key="period.id"
        cols="12"
      >
        <profile-time-period-card
          :time-period="period"
          @delete="openDeleteDialog"
          @update="
            hasRole(['ADMINISTRATIVE_STAFF']) || period.status === 'requested'
              ? (editPeriod = period)
              : null
          "
          @substitute="newTimeSpan = period"
        />
      </v-col>

      <div
        v-if="readonly && !periods.length"
        class="text-grey text-subtitle-1 mt-2 ml-6"
      >
        Kein Zeitplan angegeben
      </div>
      <v-bottom-sheet
        v-model="hasEditEntry"
        close-on-back
        data-cy="bottomSheet"
        scrollable
      >
        <v-sheet class="text-center pb-4">
          <v-form
            v-if="editPeriod"
            ref="form"
            data-cy="timePeriodForm"
          >
            <v-container>
              <v-row>
                <v-col cols="12">
                  <v-text-field
                    v-model="editPeriod.text"
                    :error-messages="textErrors"
                    label="Beschreibung"
                    variant="outlined"
                    hide-details="auto"
                    data-cy="description"
                    @blur="v$.editPeriod.text.$touch()"
                    @keydown.enter.prevent="checkPeriod()"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    v-model="editPeriod.start"
                    :label="editPeriod.type === 'TERMINATION' ? 'Kündigungsdatum' : 'Startdatum'"
                    data-cy="startDate"
                    variant="outlined"
                    type="date"
                    :max="limitDateFuture"
                    :min="limitDatePast"
                    :error-messages="startErrors"
                    hide-details="auto"
                    @focus="($event) => $event.target.showPicker()"
                  />
                </v-col>
                <v-col
                  v-if="editPeriod.type !== 'TERMINATION'"
                  cols="12"
                  md="6"
                >
                  <v-menu
                    ref="endMenu"
                    v-model="endMenu"
                    :close-on-content-click="false"
                    transition="scale-transition"
                    :min-width="$vuetify.display.mdAndDown ? '' : 'auto'"
                    max-width="400"
                    offset="20"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        v-model="editPeriod.end"
                        :label="
                          editPeriod.type === 'PART_TIME' ? 'Enddatum (Optional)' : 'Enddatum'
                        "
                        :disabled="!editPeriod.start"
                        v-bind="props"
                        data-cy="endDate"
                        variant="outlined"
                        :error-messages="endErrors"
                        type="date"
                        :max="limitDateFuture"
                        :min="editPeriod.start ? editPeriod.start : limitDatePast"
                        hide-details="auto"
                        @focus="($event) => $event.target.showPicker()"
                      >
                      </v-text-field>
                    </template>
                    <v-card>
                      <v-container>
                        <v-row dense>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ days: 1 })"
                            >
                              + 1 Tag
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ weeks: 1 })"
                            >
                              + 1 Woche
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ weeks: 2 })"
                            >
                              + 2 Wochen
                            </v-btn>
                          </v-col>
                        </v-row>

                        <v-row dense>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ months: 1 })"
                            >
                              + 1 Monat
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ months: 2 })"
                            >
                              + 2 Monate
                            </v-btn>
                          </v-col>
                          <v-col>
                            <v-btn
                              block
                              size="small"
                              variant="outlined"
                              color="primary"
                              @click="addToEnd({ months: 3 })"
                            >
                              + 3 Monate
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-card>
                  </v-menu>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  v-if="editPeriod.type === 'PART_TIME'"
                  class="grow"
                >
                  <v-text-field
                    v-model="editPeriod.percentage"
                    :error-messages="percentageErrors"
                    label="Verfügbarkeit"
                    data-cy="percentageAvailability"
                    variant="outlined"
                    hint="Arbeitszeitmodell"
                    hide-details="auto"
                    type="number"
                    step="1"
                    suffix="%"
                    @blur="v$.editPeriod.percentage.$touch()"
                  />
                </v-col>
              </v-row>
            </v-container>
          </v-form>
          <v-card-actions class="text-center">
            <v-spacer />
            <v-btn
              v-if="editPeriod?.id"
              variant="outlined"
              :class="$vuetify.display.xs ? 'px-7' : 'px-10'"
              color="red-darken-1"
              size="large"
              rounded
              data-cy="deleteTimePeriod"
              @click="openDeleteDialog()"
            >
              Löschen
            </v-btn>
            <v-spacer v-if="editPeriod?.id" />
            <v-btn
              variant="elevated"
              :class="$vuetify.display.xs ? 'px-7' : 'px-10'"
              size="large"
              rounded
              :width="!editPeriod?.id ? 250 : ''"
              color="primary"
              :loading="saveLoading"
              data-cy="saveTimePeriod"
              @click="checkPeriod()"
            >
              Speichern
            </v-btn>
            <v-spacer />
          </v-card-actions>
        </v-sheet>
      </v-bottom-sheet>
      <v-dialog
        v-model="confirmDeleteDialog"
        width="500"
        close-on-back
      >
        <v-card
          class="pt-2 pb-5 px-5"
          append-icon="$close"
        >
          <template #append>
            <v-btn
              icon="$close"
              variant="text"
              data-cy="closeConfirmDeleteDialog"
              @click="confirmDeleteDialog = false"
            ></v-btn>
          </template>
          <v-card-item class="pt-0">
            <v-row>
              <v-col>
                <v-card-text
                  style="line-height: 150%"
                  class="text-h2 text-center pt-0 mt-0"
                >
                  Möchten Sie diesen Eintrag wirklich löschen?
                </v-card-text>
              </v-col>
            </v-row>
            <v-card-actions class="">
              <v-btn
                color="primary"
                variant="elevated"
                rounded
                size="large"
                class="px-10 mx-auto"
                :loading="deleteLoading"
                data-cy="deletePeriods"
                @click="deletePeriod()"
              >
                Eintrag löschen
              </v-btn>
            </v-card-actions>
          </v-card-item>
        </v-card>
      </v-dialog>
      <schedule-substitute
        v-if="newTimeSpan"
        :key="newTimeSpan.id"
        :model-value="newTimeSpan !== null"
        :time-span="newTimeSpan"
        confirm-without-substitute-label="keine Vertretung auswählen"
        @close="substituteAction"
        @confirm="substituteAction"
      />
      <v-dialog
        v-model="scheduleHistoryDialog"
        width="1000"
        close-on-back
      >
        <v-card
          class="pt-2 pb-5 px-5"
          append-icon="$close"
        >
          <template #append>
            <v-btn
              icon="$close"
              variant="text"
              data-cy="closescheduleHistoryDialog"
              @click="scheduleHistoryDialog = false"
            ></v-btn>
          </template>
          <v-card-item class="pt-0">
            <v-row>
              <v-col>
                <v-card-text
                  v-if="isPartTimeSchedule"
                  style="line-height: 150%"
                  class="text-h2 text-center pt-0 mt-0"
                >
                  In diesem Zeitraum wurden bereits folgende Rotationen geplant. Möchten Sie diese
                  Rotationen anpassen und die Verfügbarkeit in Teilzeit übernehmen?
                </v-card-text>
                <v-card-text
                  v-else
                  style="line-height: 150%"
                  class="text-h2 text-center pt-0 mt-0"
                >
                  In diesem Zeitraum wurden bereits folgende Rotationen geplant. Möchten Sie diese
                  Rotationen löschen?
                </v-card-text>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                v-for="history in scheduleHistory.slice(0, 4)"
                :key="history.id"
                cols="6"
                data-cy="filteredEntries"
              >
                <schedule-history-card :entry="history" />
              </v-col>
            </v-row>
            <v-row v-if="remainingHistoryCount > 0">
              <v-col
                cols="12"
                class="text-center"
              >
                <p class="text-body-1 text-grey">and {{ remainingHistoryCount }} more...</p>
              </v-col>
            </v-row>
            <v-card-actions class="mt-5">
              <v-btn
                color="primary"
                variant="outlined"
                rounded
                size="large"
                class="px-10"
                :loading="historyLoading"
                data-cy="savePeriod"
                @click="savePeriod()"
              >
                Nichts anpassen
              </v-btn>
              <v-spacer />
              <v-btn
                v-if="isPartTimeSchedule"
                color="primary"
                variant="elevated"
                rounded
                size="large"
                class="px-10"
                :loading="historyLoading"
                data-cy="updateHistories"
                @click="updateHistories()"
              >
                Teilzeitmodell übernehmen
              </v-btn>
              <v-btn
                v-else
                color="primary"
                variant="elevated"
                rounded
                size="large"
                class="px-10"
                :loading="historyLoading"
                data-cy="deleteHistories"
                @click="deleteHistories()"
              >
                Rotationen löschen
              </v-btn>
            </v-card-actions>
          </v-card-item>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import 'configurable-date-input-polyfill'
import { addYears, subYears, add } from 'date-fns'
import { required, between, integer } from '@vuelidate/validators'
import { useVuelidate } from '@vuelidate/core'

export default {
  props: {
    userId: { type: String, required: true },
    hideList: { type: Boolean },
    readonly: { type: Boolean },
    deleteLoading: {
      type: Boolean,
    },
  },
  emits: ['absence-updated'],
  setup() {
    return { v$: useVuelidate() }
  },
  data() {
    return {
      confirmDeleteDialog: false,
      activePicker: null,
      startMenu: false,
      endMenu: false,
      sheet: false,
      loading: false,
      saveLoading: false,
      periods: [],
      editPeriod: null,
      newTimeSpan: null,
      substitutableTypes: ['ABSENCE'],
      periodToDelete: null,
      scheduleHistory: [],
      historyLoading: false,
      scheduleHistoryDialog: false,
    }
  },
  validations() {
    const validations = {
      editPeriod: {
        text: { required },
        percentage: { required, integer, between: between(0, 100) },
        start: { required },
        end: { required },
      },
    }
    if (this.editPeriod?.type === 'PART_TIME') {
      delete validations.editPeriod.end
    }
    return validations
  },
  computed: {
    textErrors() {
      const errors = []
      if (!this.v$.editPeriod.text.$dirty) {
        return errors
      }
      this.v$.editPeriod.text.required.$invalid && errors.push('Bitte geben Sie einen Text ein.')
      return errors
    },
    percentageErrors() {
      const errors = []
      if (!this.v$.editPeriod.percentage.$dirty) {
        return errors
      }
      ;(this.v$.editPeriod.percentage.required.$invalid ||
        this.v$.editPeriod.percentage.integer.$invalid ||
        this.v$.editPeriod.percentage.between.$invalid) &&
        errors.push('Bitte geben Sie eine Ganzzahl zwischen 0 und 100 ein.')
      return errors
    },
    startErrors() {
      const errors = []
      if (!this.v$.editPeriod.start.$dirty) {
        return errors
      }
      this.v$.editPeriod.start.required.$invalid && errors.push('Bitte geben Sie ein Datum ein.')
      return errors
    },
    endErrors() {
      const errors = []
      if (!this.v$.editPeriod.end || !this.v$.editPeriod.end.$dirty) {
        return errors
      }
      this.v$.editPeriod.end.required.$invalid && errors.push('Bitte geben Sie ein Datum ein.')
      return errors
    },
    limitDateFuture() {
      return new Date(addYears(Date.now(), 10)).toISOString().substring(0, 10)
    },
    limitDatePast() {
      return new Date(subYears(Date.now(), 20)).toISOString().substring(0, 10)
    },
    hasEditEntry: {
      get() {
        return !!this.editPeriod
      },
      set(value) {
        if (!value) {
          this.editPeriod = null
        }
      },
    },
    remainingHistoryCount() {
      return Math.max(0, this.scheduleHistory.length - 4)
    },
    isPartTimeSchedule() {
      return this.editPeriod?.percentage > 0 && this.editPeriod?.percentage < 100
    },
  },
  watch: {
    deleteLoading(value) {
      if (!value) {
        this.editPeriod = null
      }
    },
  },
  async mounted() {
    await this.loadData()
    if (
      this.$route.query.edit_time_period &&
      this.periods?.length &&
      this.hasRole('ADMINISTRATIVE_STAFF')
    ) {
      this.editPeriod = this.periods.filter(
        (per) => per.id === this.$route.query.edit_time_period
      )[0]
      this.$router.replace({ query: null })
    }
  },
  methods: {
    async loadData() {
      this.newTimeSpan = null
      this.periods = []
      this.loading = true
      await this.$cms
        .request(
          this.$readItems('time_period', {
            filter: {
              _and: [
                {
                  user: {
                    _eq: this.userId,
                  },
                },
                {
                  status: {
                    _neq: 'rejected',
                  },
                },
              ],
            },
            fields: [
              'id',
              'user.id',
              'user.title',
              'user.gender',
              'user.first_name',
              'user.last_name',
              'text',
              'percentage',
              'start',
              'end',
              'type',
              'status',
            ],
          })
        )
        .then((response) => {
          if (response) {
            this.periods = response
            this.sortDates()
            this.loading = false
          }
        })
    },
    addToEnd(duration) {
      let date = this.editPeriod.end
      if (!date) {
        date = this.editPeriod.start
      }
      if (!date) {
        date = new Date().toISOString().substring(0, 10)
      }
      const newDate = add(this.parseDate(date), duration)
      this.editPeriod.end = newDate.toISOString().substring(0, 10)
    },
    sortDates() {
      this.periods.sort(function (a, b) {
        return new Date(b.start) - new Date(a.start)
      })
    },
    formatStatus(status) {
      switch (status) {
        case 'requested':
          return 'Angefragt'
        case 'approved':
          return 'Genehmigt'
        case 'rejected':
          return 'Abgelehnt'
      }
    },
    async savePeriod() {
      this.saveLoading = true
      let response
      if (this.editPeriod.id) {
        const id = this.editPeriod.id
        const index = this.periods.findIndex((period) => period.id === id)
        // We need to create a deep clone here, because deleting the id and user as done previously
        // would emit error messages otherwise (as they are bound to a key field amongst others)
        // This way of doing this does not make the linter happy but it seems somwhat more
        // reasonable than the JSON.parse(JSON.stringify(obj)) and then deleting the properties path...
        const clonedPeriod = (({ id, user, status, ...obj }) => obj)(this.editPeriod)
        response = await this.$cms.request(
          this.$updateItem('time_period', id, clonedPeriod, {
            fields: [
              'id',
              'user.id',
              'user.title',
              'user.gender',
              'user.first_name',
              'user.last_name',
              'text',
              'percentage',
              'start',
              'end',
              'type',
              'status',
            ],
          })
        )
        this.periods[index] = response
      } else {
        response = await this.$cms.request(
          this.$createItem(
            'time_period',
            {
              ...this.editPeriod,
              user: this.userId,
            },
            {
              fields: [
                'id',
                'user.id',
                'user.title',
                'user.gender',
                'user.first_name',
                'user.last_name',
                'text',
                'percentage',
                'start',
                'end',
                'type',
                'status',
              ],
            }
          )
        )
        this.periods.push(response)
        if (
          this.substitutableTypes.includes(response.type) &&
          this.hasRole(['ADMINISTRATIVE_STAFF'])
        ) {
          this.newTimeSpan = response
        }
      }
      this.sortDates()
      this.saveLoading = false
      this.$refs.form.reset()
      this.v$.$reset()
      this.editPeriod = null
      this.scheduleHistoryDialog = false
      if (this.hasRole(['ROTATING_STAFF'])) {
        this.$router.push('/profile/timeperiods')
      }
      if (this.newTimeSpan === null) {
        this.$emit('absence-updated', response.user)
      }
    },
    deletePeriod() {
      const id = this.periodToDelete ? this.periodToDelete.id : this.editPeriod.id
      this.$cms
        .request(this.$deleteItem('time_period', id))
        .then(() => this.loadData())
        .then(() => {
          this.editPeriod = null
          this.periodToDelete = null
        })
        .then(() => (this.confirmDeleteDialog = false))
    },
    openDeleteDialog(period = null) {
      this.periodToDelete = null
      this.confirmDeleteDialog = true
      if (period) {
        this.periodToDelete = period
      }
    },
    async checkPeriod() {
      this.v$.$touch()
      if (!this.v$.$invalid) {
        if ((this.hasRole(['ADMINISTRATIVE_STAFF']), this.editPeriod.percentage < 100)) {
          await this.getScheduleHistory()
          if (this.scheduleHistory.length > 0) {
            this.scheduleHistoryDialog = true
          } else {
            await this.savePeriod()
          }
        } else {
          await this.savePeriod()
        }
      }
    },
    async updateHistories() {
      this.historyLoading = true
      await this.$cms.request(
        this.$updateItems(
          'schedule_history',
          this.scheduleHistory.map((res) => res.id),
          {
            percentage: this.editPeriod.percentage,
          }
        )
      )
      this.historyLoading = false
      await this.savePeriod()
    },
    async deleteHistories() {
      this.historyLoading = true
      this.$cms
        .request(
          this.$deleteItems(
            'schedule_history',
            this.scheduleHistory.map((res) => res.id)
          )
        )
        .then(() => {
          this.historyLoading = false
          this.savePeriod()
        })
    },
    async getScheduleHistory() {
      this.scheduleHistory = await this.$cms.request(
        this.$readItems('schedule_history', {
          filter: {
            _and: [
              {
                user: {
                  _eq: this.userId,
                },
              },
              {
                status: {
                  _neq: 'rejected',
                },
              },
              {
                start: {
                  _lte: this.editPeriod.end,
                },
              },
              {
                end: {
                  _gte: this.editPeriod.start,
                },
              },
            ],
          },
          fields: [
            'id',
            'start',
            'end',
            'user.id',
            'user.email',
            'user.first_name',
            'facility.id',
            'facility.name',
            'facility.short_name',
            'type',
            'status',
            'percentage',
          ],
          sort: ['-start'],
          limit: -1,
        })
      )
    },
    substituteAction() {
      const user = this.newTimeSpan.user
      this.newTimeSpan = null
      this.$emit('absence-updated', user)
    },
  },
}
</script>
